import React from "react";

const Contactus = () => {
  return (
    <>
      <div className="container-fluid p-4">
        <div className="container-fluid d-flex latest_card_box_ad p-0">
          <div className="contactleft map text-center">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3667.18906149343!2d77.0972695!3d23.1997796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x234d8cfe7f8e7af1!2zMjPCsDExJzU5LjIiTiA3N8KwMDUnNTAuMiJF!5e0!3m2!1sen!2sin!4v1675766829499!5m2!1sen!2sin"
              width="600"
              height="450"
              style={{ border: "0" }}
              allowfullscreen=""
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="contactright text-left">
            <div className="titlecontact">
              <h4 className="ssss">School</h4>
              <hr />
              <p className="linkwrap">
                <b className="bbb">Phone:</b>{" "}
                <a className="linkwrap mnc" href="tel:+91  8989159900">
                  +91 8989159900
                </a>
                ,&nbsp; &nbsp;
                <a className="linkwrap mnc" href="tel:+91   9753175175">
                  +91 9753175175
                </a>
                {/* <a className="linkwrap mnc" href="tel:+91  9827078309">
                 +91  9827078309
                </a> */}
              </p>
              <p className="linkwrap">
                <b className="bbb">Phone:</b>{" "}
                <a className="linkwrap mnc" href="tel:  07562-221256">
                  07562-221256
                </a>{" "}
              </p>
              <p className="linkwrap">
                <b className="bbb">Email:</b>{" "}
                <a
                  className="linkwrap mnc"
                  href="mailto: naveenvidyabharti@gmail.com "
                >
                  naveenvidyabharti@gmail.com
                </a>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contactus;
