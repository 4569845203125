import React from "react";

const Ourschool = () => {
  return (
    <>
      <div className="our-school">
        <div className="our-row">
          <div className="our-col">
            <h1>Our School</h1>
            <p>
              Naveen Vidya Bharti Hr. Sec. School is based in sehore
              city. Naveen Vidya Bharti Hr. Sec. School is recognized as a
              developmentally enriching learning space, making learning fun for
              children. Naveen Vidya Bharti Hr. Sec. School  was founded and
              established in June 2004. Based on the educational philosophy of “
              Child is Supreme” , we offer a child-centric education where every
              aspect of the child’s personality is nurtured and groomed with
              love and affection. We craft glowing pearls that are the pride of
              nation, school and family. Our expertise teaching staff is
              committed to the broadened diversified technology and innovative
              pedagogies, with inter-disciplinary approach.Our work experiences
              are becoming more collaborative each day and are constantly
              expanding. If we want educational experiences & lifelong learning
              to be both experimental and relevant, partnering is the vehicle.
              The expert and well trained teachers of Naveen Vidya Bharti Hr.
              Sec. School ensures all round development of each and every
              student in the class. NVBHSS Management focuses not only on
              providing the best of educational facilities but also works round
              the clock on Cultural, Co-Curricular and Physical Fitness
              development of the students. Different activities are conducted to
              create awareness about the current situations. The basic Vision of
              Team NVBHSS is a holistic development of each student
              .......right from......!!!
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ourschool;
