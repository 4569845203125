import React from 'react'

const Gallery = () => {
  return (
    <>
     <div className="container-fluid">
     <div className="first">
        
        <div className="one">
            <img src="./img/g1.jpeg" alt="" />
        </div>
        <div className="one">
            <img src="./img/g2.jpeg" alt="" />
        </div>
        <div className="one">
            <img src="./img/g3.jpeg" alt="" />
        </div><div className="one">
            <img src="./img/g4.jpeg" alt="" />
        </div>
    </div>
    <div className="first">
        
        <div className="one">
            <img src="./img/n1.jpeg" alt="" />
        </div>
        <div className="one">
            <img src="./img/n2.jpeg" alt="" />
        </div>
        <div className="one">
            <img src="./img/n3.jpeg" alt="" />
        </div><div className="one">
            <img src="./img/n4.jpeg" alt="" />
        </div>
    </div>
    <div className="first">
        
            <div className="one">
                <img src="./img/n5.jpeg" alt="" />
            </div>
            <div className="one">
                <img src="./img/n6.jpeg" alt="" />
            </div><div className="one">
                <img src="./img/n7.jpeg" alt="" />
            </div><div className="one">
                <img src="./img/n8.jpeg" alt="" />
            </div>
        </div>
        <div className="first">
        
            <div className="one">
                <img src="./img/g5.jpeg" alt="" />
            </div>
            <div className="one">
                <img src="./img/g6.jpeg" alt="" />
            </div><div className="one">
                <img src="./img/g7.jpeg" alt="" />
            </div><div className="one">
                <img src="./img/g8.jpeg" alt="" />
            </div>
        </div>

        <div className="first">
            <div className="one">
                <img src="./img/n9.jpeg" alt="" />
            </div>
            <div className="one">
                <img src="./img/n10.jpeg" alt="" />
            </div><div className="one">
                <img src="./img/n11.jpeg" alt="" />
            </div><div className="one">
                <img src="./img/n12.jpeg" alt="" />
            </div>
        </div>
        <div className="first">
            <div className="one">
                <img src="./img/g13.jpeg" alt="" />
            </div>
            <div className="one">
                <img src="./img/g10.jpeg" alt="" />
            </div><div className="one">
                <img src="./img/g11.jpeg" alt="" />
            </div><div className="one">
                <img src="./img/g14.jpeg" alt="" />
            </div>
        </div>

        <div className="first">
            <div className="one">
                <img src="./img/n14.jpeg" alt="" />
            </div>
            <div className="one">
                <img src="./img/n15.jpeg" alt="" />
            </div><div className="one">
                <img src="./img/n16.jpeg" alt="" />
            </div><div className="one">
                <img src="./img/n17.jpeg" alt="" />
            </div>
        </div>

        <div className="first">
            <div className="one">
                <img src="./img/n18.jpeg" alt="" />
            </div>
            <div className="one">
                <img src="./img/cultural.jpeg" alt="" />
            </div>
            <div className="one">
                <img src="./img/g13.jpeg" alt="s" /> 
            </div>
            <div className="one">
                <img src="./img/n19.jpeg" alt="" />
            </div>
        </div>
    </div>
      
    </>
  )
}

export default Gallery
