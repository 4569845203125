import React from "react";

const Facilities = () => {
  return (
    <>
      <div className="container-fluid p-0">
        <div className="container liscontainer">
          <div className="facilitycontent">
            {/* swimming */}
            <div className="facilitCard">
              <div className="facilimages">
                <img src="./img/yoga.jpeg" alt="" />
              </div>
              <div className="facontent">
                <div className="factitle">Fitness & Yoga</div>
                <p>
                  Yoga at school might help students feel better and improve
                  their grades, Hygiene, fitness and "Good Living Skill" taught
                  to students.
                </p>
              </div>
            </div>

            {/* skilled */}

            <div className="facilitCard">
              <div className="facilimages">
                <img src="./img/skilled_staff.jpeg" alt="" />
              </div>
              <div className="facontent">
                <div className="factitle">Skilled Staff</div>
                <p>
                  Skilled Staff is here to teach or guide for your bright
                  carrier and and provides Hands on in our School.Teachers play a
                  key role in identifying and supporting students who are at
                  risk of dropping out, especially students from vulnerable
                  populations, such as girls ...
                </p>
              </div>
            </div>

            {/* sports */}
            <div className="facilitCard">
              <div className="facilimages">
                <img src="./img/plant.jpeg" alt="" />
              </div>
              <div className="facontent">
                <div className="factitle">Plantation</div>
                <p>
                  A tree plantation drive was recently carried out by the
                  students of class 1 and 2 to create awareness and spread the
                  message of saving our planet- 'Protecting our environment is
                  the need of an hour. ' Students brought different types of
                  saplings and planted them outside the school campus.
                </p>
              </div>
            </div>

            {/* nice Infrastructure */}

            <div className="facilitCard">
              <div className="facilimages">
                <img src="./img/infra2.jpeg" alt="" />
              </div>
              <div className="facontent">
                <div className="factitle">Nice Infrastructure</div>
                <p>
                  The fact is that a good school infrastructure, with renewed
                  spaces, makes it possible for children and youths that live in
                  remote areas to study .A good school infrastructure makes it a
                  good place for the children to study, which is important as
                  the student spends maximum time in school.
                </p>
              </div>
            </div>

            {/* hygiene washroom */}
            <div className="facilitCard">
              <div className="facilimages">
                <img src="./img/cultural.jpeg" alt="" />
              </div>
              <div className="facontent">
                <div className="factitle">Cultural Activities</div>
                <p>
                  The medical camps are conducted by qualified doctors and
                  nurses either employed by Hand in Hand or from reputed
                  hospitals. Patients with mild disorders are prescribed
                  medication during the camp and those with serious disorders
                  are referred to the nearest primary healthcare centre.
                </p>
              </div>
            </div>

            {/* Good Academic */}

            <div className="facilitCard">
              <div className="facilimages">
                <img src="./img/good.jpeg" alt="" />
              </div>
              <div className="facontent">
                <div className="factitle">Good Academic</div>
                <p>
                  We provide our student with good encyclopedia to reduce their
                  encumbrance regarding any subject academically or globally. A
                  well equipped laboratories of physics, chemistry, biology and
                  computers for practical learning .We teach students by smart
                  classes for active participation and deep knowledge. A good
                  psychiatrist and counselor for students and Parents is
                  available during school timings.
                </p>
              </div>
            </div>

            {/* transport facility */}

            <div className="facilitCard">
              <div className="facilimages">
                <img src="./img/trans.jpeg" alt="" />
              </div>
              <div className="facontent">
                <div className="factitle">Transport Facility</div>
                <p>
                  The school provides transport facility to the students through
                  Buses & Magic for transporting the students from different
                  localities to the school and vice-versa. Students can avail
                  the Transport facility by putting up an application. All the
                  buses have trained driver’s and conductor’s. The school
                  follows all the rules and regulations of M.P. Government for
                  the buses.
                </p>
              </div>
            </div>

            {/* smart education fac */}

            <div className="facilitCard">
              <div className="facilimages">
                <img src="./img/atal_lab.jpeg" alt="" />
              </div>
              <div className="facontent">
                <div className="factitle">ATAL Innovation Lab</div>
                <p>
                  {" "}
                  With a vision to 'Cultivate one Million children in India as
                  Neoteric Innovators', Atal Innovation Mission is establishing
                  Atal Tinkering Laboratories (ATLs) in schools across India.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Facilities;
