import React from "react";
import { FaInstagram,FaFacebook,FaTwitter} from 'react-icons/fa'

const Footer = () => {
  return (
    <>
      <div class="main-footer">
        <div className="footer-top">
          <div className="footerlogo-img">
            <img src="./img/logo.png" alt="" />
          </div>
          <div className="footer-content">
            <p>Have any questions?</p>
            <p>
              Call Us: 8989159900, 9753175175, 07562-221256, Mail Us:
              naveenvidyabharti@gmail.com
            </p>
            <p>
              Address - Vaishali Nagar Near Civil Court, Sehore, Madhya Pradesh
              466001 
            </p>
          </div>
          <div className="socialmedia-link">
            <div className="socialall">
              <a
                href="https://www.facebook.com/pages/Naveen-Vidya-Bharti-Higher-Secondary-School/186139935439716"
                target="_blank"
              >
                <div className="alllink-soc">
                  <FaFacebook />
                </div>
              </a>
              <a
                href="https://twitter.com/praveemishra07?t=Zyj73eSnYnhdrQZaj1t80A&s=08"
                target="_blank"
              >
                {" "}
                <div className="alllink-soc">
                  <FaTwitter />
                </div>
              </a>
              <a
                href="https://instagram.com/naveenvidyabhartiatlab?igshid=ZDdkNTZiNTM="
                target="_blank"
              >
                {" "}
                <div className="alllink-soc">
                  <FaInstagram />
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <p>© All Rights Reserved by, Naveen Vidya Bharti Hr.Sec. School</p>
          <p className="footer-made">
            Made with <i class=" ri-heart-3-fill"></i> by{" "}
            <a href="https://maitretech.com/"> Maitretech Solution</a>
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
